import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import Head from 'next/head';
import Header from '../shared/Header';

const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 1;
`;

const StyledMain = styled.main`
  height: 100%;
`;

interface Props {
  children?: ReactNode;
  fromPhysicianRecruitment?: boolean;
}

const WebLayout: FunctionComponent<Props> = ({
  children,
  fromPhysicianRecruitment = false,
}: Props) => (
  <Fragment>
    <Head>
      <title>MDLIVE: Board-Certified Doctors on Call 24/7</title>
      <meta
        name="description"
        content="MDLIVE offers reliable 24/7 health care by phone or video. Access board-certified doctors anytime, anywhere, including weekends, nights, and holidays. MDLIVE doctors and therapists have an average of over 10 years of experience."
      ></meta>
    </Head>
    <StyledHeader
      showNav
      dark
      fromPhysicianRecruitment={fromPhysicianRecruitment}
    />
    <StyledMain>{children}</StyledMain>
  </Fragment>
);

export default WebLayout;
